import http from '../http';

export function getList (params) {
    return http({
        url: "/api/project/redStatsPage",
        method: "get",
        params:params
    })
}
export function getListAll (params) {
    return http({
        url: "/api/project/redStatsPageAll",
        method: "get",
        params:params
    })
}

export function getDetile (params) {
    return http({
        url: "/api/project/getRedDetail",
        method: "get",
        params:params
    })
}
