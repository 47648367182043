<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"

    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />
    <el-date-picker
        v-model="time"
        type="month"
        @change="initLoad"
        v-if="this.tabStatus == 0"
        style="margin-bottom: 15px"
        placeholder="选择月">
    </el-date-picker>
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        v-if="this.tabStatus == 0"
        @pageCurrentChange="pageCurrentChange"
        @detileProject="detileProject"
        @passOrder="passOrder"
        @editProject="editProject"
        @discardProject="discardProject"
    />
    <common-table
        :tableData="tableDataAll"
        :tableProp="tablePropAll"
        v-if="this.tabStatus == 1"
        @pageCurrentChange="pageCurrentChange"
        @detileProject="detileProject"
        @passOrder="passOrder"
        @editProject="editProject"
        @discardProject="discardProject"
    />
    <!--addDialog 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="addDialogData.title"
        :visible.sync="addDialogData.isDialog"
        :top="addDialogData.top"
        :width="addDialogData.width"
    >
      <div>
        <el-row>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form ref="form"  label-width="100px">
                <el-form-item label="姓名">
                  {{addDialogData.addDialogShowData.name}}
                </el-form-item>
                <el-form-item label="部门">
                  {{addDialogData.addDialogShowData.department_name}}
                </el-form-item>
                <el-form-item label="分红金额">
                  {{addDialogData.addDialogShowData.all_red_price}}
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <el-form ref="form"  label-width="160px">
                <el-form-item label="工号">
                  {{addDialogData.addDialogShowData.user_id}}
                </el-form-item>
                <el-form-item label="职级">
                  {{addDialogData.addDialogShowData.station_name}}
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
        <div>
          <common-table
              :tableData="tableDataDetile"
              :tableProp="tablePropDetile"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {timer} from '@/assets/js/comment'
import {getDetile, getList, getListAll} from '@/api/project_management/dividend_leaderboard'

export default {
  components: {},
  props: [],
  data() {
    return {
      time: '',
      tabStatus: 0,
      //title 数据源
      titleProp: {
        name: '分红排行榜',//标题名称
        butOperate: []
      },
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: false,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '状态',
          field: 'status',
          type: 2,
        }, {
          name: '项目名称',
          field: 'project_title',
          type: 1,
        }, {
          name: '项目类型',
          field: 'type_id',
          type: 2,
        }, {
          name: '商务负责人',
          field: 'name',
          type: 2,
        }, {
          name: '客户名称',
          field: 'customer',
          type: 1,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        // 状态 下拉的值
        statusOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 项目类型 下拉的值
        type_idOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],
        // 商务负责人 下拉的值
        nameOptions: [
          //{value: '选项1',label: '黄金糕'}
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', project_title: '', type_id: '', name: '', customer: '',},
        //tabs 标题
        tabsData: [{
          label: '月度排行榜',
          name: '0',
          total: 0,
        }, {
          label: '总排行榜',
          name: '1',
          total: 0,
        },]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '月份',
          field: 'month',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '姓名',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name'
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '岗位名称',
          field: 'station_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },
        //   {
        //   title: '分红项目数量',
        //   field: 'project_num',
        //   isWidth: false,
        //   width: '120px',
        //   headerAlign: 'center',
        //   align: 'center',
        //   tooltip: false,
        //   type: 0,
        // },
          {
          title: '分红金额',
          field: 'red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '详情',
            type: 'primary',
            method: 'detileProject',
            status: '20000',
            id: 20000,
            }],
        }],
      },
      tablePropAll: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '姓名',
          field: 'user_info',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '岗位名称',
          field: 'station_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },
        //   {
        //   title: '分红项目数量',
        //   field: 'all_project_num',
        //   isWidth: false,
        //   width: '120px',
        //   headerAlign: 'center',
        //   align: 'center',
        //   tooltip: false,
        //   type: 0,
        // },
          {
          title: '分红金额',
          field: 'red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '详情',
            type: 'primary',
            method: 'detileProject',
            status: '20000',
            id: 20000,
            }],
        }],
      },
      tablePropDetile: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '项目名称',
          field: 'project',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'project_title'
        }, {
          title: '累计分红基数金额',
          field: 'project_all_red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '分红比例',
          field: 'red_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '分红金额',
          field: 'red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '分红日期',
          field: 'complete_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }],
      },
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '分红详情',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          name: '',
          user_id: '',
          department_name: '',
          station_name: '',
          all_red_price: '',
        },

      },
      //表格数据
      tableData: [],
      tableDataAll: [],
      tableDataDetile: [],
      //addDialog提交数据
      subData: {}

    };
  },
  created() {
    this.time = new Date(new Date().toLocaleDateString()).getTime() - 31 * 24 * 3600 * 1000;
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    start_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    end_timeChange() {
      this.addDialogData.addDialogShowData.cycle_time = timer(this.addDialogData.addDialogShowData.end_time - this.addDialogData.addDialogShowData.start_time)
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        time: this.time / 1000
// keywords: this.tabsProp.keywords,
// this.tabsProp.active //tabs 当前的值
// name: this.tabsProp.heighKewWordField.name
      }
      params.time == 0 ? params.time = '' : ''
      getList(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].user_station) {
            res.data[i].department_name = res.data[i].user_station.department_name
            res.data[i].station_name = res.data[i].user_station.station_name
          }
        }
        this.tableData = res.data
      })
    },
    //总排行榜
    initLoadAll() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
// keywords: this.tabsProp.keywords,
// this.tabsProp.active //tabs 当前的值
// name: this.tabsProp.heighKewWordField.name
      }
      getListAll(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tablePropAll.page.current_page = res.current_page;
        this.tablePropAll.page.total = res.total;
        this.tablePropAll.page.size = res.per_page;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].user_station) {
            res.data[i].department_name = res.data[i].user_station.department_name
            res.data[i].station_name = res.data[i].user_station.station_name
          }
        }
        this.tableDataAll = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.tabStatus = tab.name
      tab.name == 0?this.initLoad(): this.initLoadAll()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 详情 按钮点击
    detileProject(row, index) {
      console.log(row)
      console.log(index)
      let params = {user_id: row.user_id, time: this.time / 1000}
      if(this.time / 1000 == 0){
        params = {user_id: row.user_id, time: ''}
      }
      if(this.tabStatus==1){
        params.time = 0
      }


      getDetile(params).then((result)=>{
        console.log(result)
        this.addDialogData.isDialog = true
        this.addDialogData.addDialogShowData.name = result.data.user.user_info.name
        this.addDialogData.addDialogShowData.user_id = result.data.user.user_info.user_id
        this.addDialogData.addDialogShowData.department_name = result.data.user.user_station.department_name
        this.addDialogData.addDialogShowData.station_name = result.data.user.user_station.station_name
        this.addDialogData.addDialogShowData.all_red_price = result.data.user.all_red_price

        this.tableDataDetile = result.data.data
      })
      .catch((error) => {
        console.log(error);
        this.$message({
          message: error.msg,
          type: 'error'
        });
      })
    },
    //table 确认成单 按钮点击
    passOrder(row, index) {
      console.log(row)
      console.log(index)
      console.log('确认成单 点击')
    },
    //table 修改 按钮点击
    editProject(row) {
      console.log(row)
      return false;
      // this.addDialogData.addDialogShowData = this.$options.data().addDialogData.addDialogShowData;
      // this.addDialogData.title = '修改';
      // let params = {id: row.id}
      // console.log(params)
      // projectDetial(params).then((result) => {
      //   let res = result.data;
      //   res.is_external = res.project_price.is_external
      //   res.source_cost = res.project_price.source_cost
      //   res.market_cost = res.project_price.market_cost
      //   res.external_cost = res.project_price.external_cost
      //   res.attachment_json = JSON.parse(res.attachment_json)
      //   res.end_time = res.end_time * 1000
      //   res.start_time = res.start_time * 1000
      //
      //   this.addDialogData.addDialogShowData = res
      //   console.log(this.addDialogData.addDialogShowData)
      // })
      // this.addDialogData.isDialog = true;
    },
    //table 作废 按钮点击
    discardProject(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      this.subData = {...this.addDialogData.addDialogShowData}  //ES6深拷贝
      this.subData.start_time = this.subData.start_time / 1000;
      this.subData.end_time = this.subData.end_time / 1000;
      this.subData.attachment_json = JSON.stringify(this.subData.attachment_json);

      // editProject(this.subData).then((result) => {
      //   this.addDialogData.isDialog = false;
      //   this.$message({
      //     message: result.msg,
      //     type: 'success'
      //   });
      // })
      //     .catch((error) => {
      //       console.log(22);
      //       this.$message({
      //         message: error.msg,
      //         type: 'error'
      //       });
      //     })
    },


  },
};
</script>
<style lang="scss" scoped></style>
